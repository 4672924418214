














































































import { RouteMeta, routes } from "@/router";
import { catNav, catVue } from "@/util/logging";

import { PermissionType } from "@/models/UserDataModel";
import { RouteConfig } from "vue-router";
import Vue from "vue";

export default Vue.extend({
  name: "SideNavigation",

  methods: {
    routePath(route: RouteConfig): string {
      return route.meta && "navpath" in route.meta
        ? route.meta.navpath
        : route.path;
    },
    childPath(route: RouteConfig, child: RouteConfig): string {
      return this.routePath(route) + "/" + this.routePath(child);
    },

    checkRouteAndSubroute(route: RouteConfig): boolean {
      let showMe = false;

      if (!route.children || route.children.length == 0) return false;

      route.children.forEach(
        (child) => (showMe = showMe || this.checkRoute(child.meta as RouteMeta))
      );
      return showMe || this.checkRoute(route.meta as RouteMeta);
    },

    checkRoute(meta: RouteMeta | undefined): boolean {
      if (meta === undefined) return false;

      // Hide hidden elements
      if ("hidden" in meta && meta.hidden) return false;

      // Hide entries that require authentication if unauthenticated.
      if (!this.$store.state.auth.loggedIn) {
        if (meta.requiresAuth) return false;
        return true;
      }

      // console.log( "   loggedIn ... ");
      if (meta.hideAuthenticated) return false;

      // console.log( "   not hiding ... "+(meta.permissionMask!==undefined)+"-"+(meta.permissionMask!=0)+"-"+(( meta.permissionMask&this.$store.state.auth.user.permissions )==0));
      if (
        meta.permissionMask !== undefined &&
        meta.permissionMask != 0 &&
        (meta.permissionMask & this.$store.state.auth.user.Permissions) == 0
      )
        return false;

      if (process.env.NODE_ENV != "development") {
        if (
          meta.permissionMask !== undefined &&
          meta.permissionMask != 0 &&
          (meta.permissionMask & PermissionType.PERM_THOMAS) ==
            PermissionType.PERM_THOMAS &&
          this.$store.state.auth.user.Name != "Thomas Ganter"
        )
          return false;
      }

      // Hide hidden elements
      if ("mandantFeature" in meta) {
        const what = ":" + meta.mandantFeature;
        //console.log("checking for '" + what + "'");

        return this.$store.state.auth.mandant.features.includes(what);
      }

      return true;
    },
  },

  computed: {
    routes() {
      catNav.info("Accessing routes: ");
      catNav.debug(JSON.stringify(routes, null, 4));

      return routes;
    },
  },

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... `);
  },
});
